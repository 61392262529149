import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import CafeUsers from '../modules/CafeUsers';
import CafeCorporateAccounts from '../modules/CafeCorporateAccounts';
import { fetchItems } from '../services/cosmosdbService';

const CafeScreen = () => {
  const { id } = useParams(); // Extract cafe ID from the URL
  const navigate = useNavigate(); // To navigate back to UserScreen
  const [activeTab, setActiveTab] = useState('CafeUsers');
  const [cafeData, setCafeData] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCafeData = async () => {
      console.log(`Fetching data for cafe ID: ${id}`); // Log the cafe ID being used

      try {
        setLoading(true);
        setError('');
        const databaseId = 'brewserdb';

        // Fetch cafe details
        const [fetchedCafe] = await fetchItems(
          databaseId,
          'cafes',
          "SELECT * FROM c WHERE c.id = @id",
          [{ name: '@id', value: id }]
        );

        if (!fetchedCafe) {
          setError('No data found for this cafe.');
          return;
        }

        console.log('Fetched Cafe Data:', fetchedCafe);
        setCafeData(fetchedCafe);

        // Fetch staff assignments for the cafe
        const staffAssignments = await fetchItems(
          databaseId,
          'cafe_assignments',
          "SELECT * FROM c WHERE c.cafeId = @cafeId",
          [{ name: '@cafeId', value: id }]
        );
        console.log('Staff Assignments:', staffAssignments);

        // Fetch staff details for each assignment
        const staffDetails = await Promise.all(
          staffAssignments.map(async (assignment) => {
            const [userDetails] = await fetchItems(
              databaseId,
              'accounts',
              "SELECT * FROM c WHERE c.id = @userId",
              [{ name: '@userId', value: assignment.userId }]
            );
            return {
              ...assignment,
              firstName: userDetails?.firstName || '',
              lastName: userDetails?.lastName || '',
              email: userDetails?.email || '',
            };
          })
        );
        console.log('Staff Details:', staffDetails);

        setStaffList(staffDetails);
      } catch (err) {
        console.error('Error fetching cafe data:', err);
        setError('Failed to load cafe data.');
      } finally {
        setLoading(false);
        console.log('Finished fetching cafe data.'); // Log when fetching is complete
      }
    };

    if (id) {
      fetchCafeData();
    } else {
      console.error('No cafe ID provided in URL parameters.');
      setError('Invalid cafe ID.');
      setLoading(false);
    }
  }, [id]);

  const handleExit = () => {
    console.log('Navigating back to UserScreen.');
    navigate('/user');
  };

  const renderActiveTab = () => {
    if (loading) {
      return <p>Loading...</p>;
    }
    if (error) {
      return <p style={{ color: 'red' }}>{error}</p>;
    }

    switch (activeTab) {
      case 'CafeUsers':
        return <CafeUsers cafe={cafeData} staffList={staffList} />;
      case 'CafeCorporateAccounts':
        return <CafeCorporateAccounts cafeLinkingCode={cafeData?.linkingCode} />;
      default:
        return <CafeUsers cafe={cafeData} staffList={staffList} />;
    }
  };

  const styles = {
    container: {
      paddingTop: '80px',
    },
    tabMenu: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      borderBottom: '1px solid #ccc',
    },
    tabItem: {
      margin: '0 15px',
      cursor: 'pointer',
      fontWeight: 'normal',
    },
    tabItemActive: {
      fontWeight: 'bold',
    },
  };

  return (
    <div style={styles.container}>
      <Header title={cafeData ? cafeData.cafeName : 'Loading Cafe...'} />
      <div style={styles.tabMenu}>
        <span
          style={{
            ...styles.tabItem,
            ...(activeTab === 'CafeUsers' ? styles.tabItemActive : {}),
          }}
          onClick={() => setActiveTab('CafeUsers')}
        >
          Users
        </span>
        <span
          style={{
            ...styles.tabItem,
            ...(activeTab === 'CafeCorporateAccounts' ? styles.tabItemActive : {}),
          }}
          onClick={() => setActiveTab('CafeCorporateAccounts')}
        >
          Corporate Accounts
        </span>
        <span
          style={{
            ...styles.tabItem,
          }}
          onClick={handleExit}
        >
          Exit
        </span>
      </div>
      {renderActiveTab()}
    </div>
  );
};

export default CafeScreen;
