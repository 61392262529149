import React, { useEffect, useState, useContext } from 'react';
import Header from '../components/Header';
import Container from '../components/Container';
import { fetchItems } from '../services/cosmosdbService';
import { UserContext } from '../context/UserContext';

const CafeSelectionScreen = () => {
  const [cafes, setCafes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchCafes = async () => {
      setLoading(true);
      try {
        const cafeQuery = "SELECT * FROM c WHERE c.userId = @userId";
        const parameters = [{ name: "@userId", value: user.userId }];

        const fetchedCafes = await fetchItems('brewserdb', 'cafe_assignments', cafeQuery, parameters);
        const detailedCafes = await Promise.all(
          fetchedCafes.map(async (assignment) => {
            const [cafeDetails] = await fetchItems(
              'brewserdb',
              'cafes',
              "SELECT * FROM c WHERE c.id = @cafeId",
              [{ name: '@cafeId', value: assignment.cafeId }]
            );
            return cafeDetails;
          })
        );
        setCafes(detailedCafes);
      } catch (error) {
        console.error("Error fetching cafes:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.userId) {
      fetchCafes();
    }
  }, [user.userId]);

  const styles = {
    pageWrapper: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Horizontally center the content
      paddingTop: '100px',
      textAlign: 'center',
    },
    listTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    list: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      maxWidth: '600px',
    },
    listItem: {
      padding: '10px',
      borderBottom: '1px solid #ccc',
      color: '#333',
    },
  };

  return (
    <div style={styles.pageWrapper}>
      <Header />
      <Container>
        {loading && <p>Loading cafes...</p>}
        {!loading && cafes.length > 0 ? (
          <>
            <div style={styles.listTitle}>Your Cafes</div>
            <ul style={styles.list}>
              {cafes.map((cafe) => (
                <li key={cafe.id} style={styles.listItem}>
                  {cafe?.name || 'Unnamed Cafe'}
                </li>
              ))}
            </ul>
          </>
        ) : (
          !loading && <p>No cafes assigned to your account.</p>
        )}
      </Container>
    </div>
  );
};

export default CafeSelectionScreen;
