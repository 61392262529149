import React, { useState } from 'react';
import { fetchItems, addItem, deleteItem } from '../services/cosmosdbService';

const CafeUsers = ({ cafe, staffList: initialStaffList }) => {
  const [staffList, setStaffList] = useState(initialStaffList || []);
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInviteStaff = async () => {
    if (!email) {
      setErrorMessage('Please enter an email address.');
      return;
    }

    try {
      setErrorMessage('');
      setSuccessMessage(''); // Clear previous success messages

      // Verify the email exists in the accounts container
      const [userDetails] = await fetchItems(
        'brewserdb',
        'accounts',
        "SELECT * FROM c WHERE c.email = @userEmail",
        [{ name: '@userEmail', value: email }]
      );

      if (!userDetails) {
        setErrorMessage('The provided email does not belong to a registered user.');
        return;
      }

      // Add a new staff assignment
      const newAssignment = {
        id: `${Date.now()}`, // Unique ID
        cafeId: cafe.id,
        userId: userDetails.id,
        role: 'staff',
      };

      await addItem('brewserdb', 'cafe_assignments', newAssignment);

      // Update staff list with the new staff member
      setStaffList((prevList) => [
        ...prevList,
        {
          ...newAssignment,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email,
        },
      ]);

      setEmail(''); // Clear the input field
      setSuccessMessage('Staff member added successfully.');
    } catch (error) {
      console.error('Error adding staff member:', error);
      setErrorMessage('Failed to add staff member. Please try again.');
    }
  };

  const handleRemoveStaff = async (assignmentId) => {
    try {
      await deleteItem('brewserdb', 'cafe_assignments', assignmentId);

      // Remove the staff member from the list
      setStaffList((prevList) => prevList.filter((staff) => staff.id !== assignmentId));

      setSuccessMessage('Staff member removed successfully.');
    } catch (error) {
      console.error('Error removing staff member:', error);
      setErrorMessage('Failed to remove staff member. Please try again.');
    }
  };

  const styles = {
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      minHeight: '100vh',
      paddingTop: '50px',
    },
    formContainer: {
      padding: '40px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
      textAlign: 'center',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '15px',
      borderRadius: '5px',
      border: '1px solid #DDDDDD',
    },
    button: {
      width: '100%',
      backgroundColor: '#F57F17',
      padding: '10px',
      borderRadius: '5px',
      color: '#FFF',
      cursor: 'pointer',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    staffCard: {
      backgroundColor: '#FFFFFF',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '10px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    },
    smallText: {
      fontSize: '14px',
      color: '#333',
    },
    errorText: {
      color: '#b71c1c',
      marginBottom: '10px',
    },
    successText: {
      color: 'green',
      marginBottom: '10px',
    },
    removeButton: {
      backgroundColor: '#b71c1c',
      color: '#FFF',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: 'none',
      marginTop: '10px',
    },
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.title}>{cafe?.cafeName || 'Cafe Admin'}</h1>
        <p>Manage staff for this cafe.</p>

        {errorMessage && <p style={styles.errorText}>{errorMessage}</p>}
        {successMessage && <p style={styles.successText}>{successMessage}</p>}

        {/* Input for inviting staff */}
        <input
          style={styles.input}
          type="email"
          placeholder="Enter staff member's email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div style={styles.button} onClick={handleInviteStaff}>
          Invite Staff
        </div>

        {/* Display staff list */}
        <div>
          {staffList.map((staff) => (
            <div key={staff.id} style={styles.staffCard}>
              <p style={styles.smallText}>
                {staff.firstName} {staff.lastName} ({staff.email})
              </p>
              <p style={styles.smallText}>Role: {staff.role}</p>
              <button
                style={styles.removeButton}
                onClick={() => handleRemoveStaff(staff.id)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CafeUsers;
