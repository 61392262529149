import React, { useState } from 'react';
import Header from './Header';
import Menu from './Menu';

const Screen = ({ title, menuItems = [], children }) => {
  const [activeModule, setActiveModule] = useState(menuItems[0]?.key || null); // Default to null if no menu items

  const styles = {
    screenContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100vh', // Full viewport height
    },
    contentContainer: {
      flex: 1, // Take up remaining space
      margin: '100px 50px 50px 50px', // Add side and bottom margins
      borderRadius: '10px', // Rounded edges
      backgroundColor: '#FFFFFF', // Clean background
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow
      overflow: 'hidden', // Prevent content overflow
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 'calc(100vh)', // Prevent exceeding screen height
    },
    menuContainer: {
      padding: '25px 25px 0px 25px',
    },
    moduleContainer: {
      flexGrow: 1, // Allow module content to fill remaining space
      overflow: 'auto', // Enable scrolling for large content
      padding: '25px',
    },
  };

  return (
    <div style={styles.screenContainer}>
      {/* Header */}
      <Header title={title} />

      {/* Content */}
      <div style={styles.contentContainer}>
        {/* Menu */}
        {menuItems.length > 0 ? (
          <div style={styles.menuContainer}>
            <Menu
              title={title} // Pass the title to the Menu component
              menuItems={menuItems}
              activeModule={activeModule}
              onModuleSelect={(moduleKey) => setActiveModule(moduleKey)}
            />
          </div>
        ) : (
          <>{children}</> // Render children if no menu items are provided
        )}

        {/* Module Content */}
        {menuItems.length > 0 && (
          <div style={styles.moduleContainer}>
            {menuItems.map(
              (menuItem) =>
                activeModule === menuItem.key && <menuItem.component key={menuItem.key} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Screen;
