import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { signupUser } from '../services/apiService';
import { UserContext } from '../context/UserContext';

const SignupScreen = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useContext(UserContext); // Access login function from context
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    setLoading(true);
    setError('');

    // Required field validation
    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    // Email validation
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    // Password match validation
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    try {
      // Register the user
      const newUser = { email, password, firstName, lastName };
      const userData = await signupUser(newUser);

      // Store user data in UserContext
      login(userData);

      // Navigate based on role or attributes
      navigate('/user'); // Redirect to user screen or any other desired screen
    } catch (error) {
      console.error("Error signing up:", error);
      setError(error.message || "An error occurred during signup. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100vh',
      overflow: 'hidden',
    },
    navbar: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    logo: {
      fontSize: '50px',
      color: '#333',
      fontWeight: 'bold',
      fontFamily: 'Cowboy Hippie Pro, sans-serif',
    },
    navLinks: {
      display: 'flex',
      gap: '20px',
    },
    navLink: {
      color: '#000000',
      fontSize: '20px',
      fontFamily: 'Anton, sans-serif',
      fontWeight: 'normal',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      padding: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'white',
      width: '100%',
      maxWidth: '400px',
    },
    title: {
      fontSize: '24px',
      color: '#ff7f00',
      textAlign: 'center',
      fontWeight: '600',
    },
    input: {
      width: '100%',
      padding: '12px',
      marginBottom: '15px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      boxSizing: 'border-box',
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#ff7f00',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '16px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: (!firstName || !lastName || !email || !password || !confirmPassword) ? '0.6' : '1',
      pointerEvents: (!firstName || !lastName || !email || !password || !confirmPassword) ? 'none' : 'auto',
    },
    spinner: {
      border: '3px solid rgba(255, 255, 255, 0.3)',
      borderTop: '3px solid white',
      borderRadius: '50%',
      width: '16px',
      height: '16px',
      animation: 'spin 1s linear infinite',
    },
    errorMessageContainer: {
      height: '40px',
      marginTop: '15px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    errorMessage: {
      color: '#b71c1c',
      backgroundColor: '#ffebee',
      border: '1px solid #f44336',
      padding: '10px',
      borderRadius: '4px',
      textAlign: 'center',
      fontSize: '0.9rem',
      width: '100%',
      boxSizing: 'border-box',
    },
  };

  return (
    <div style={styles.pageContainer}>
      <header style={styles.navbar}>
        <h1 style={styles.logo}>Brewser</h1>
        <nav style={styles.navLinks}>
          <span style={styles.navLink} onClick={() => navigate('/')}>Home</span>
          <span style={styles.navLink} onClick={() => navigate('/help')}>Help</span>
          <span style={styles.navLink} onClick={() => navigate('/login')}>Login</span>
          <span style={styles.navLink} onClick={() => navigate('/signup')}>Signup</span>
        </nav>
      </header>

      <div style={styles.container}>
        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
        <div style={styles.form}>
          <h1 style={styles.title}>Sign Up</h1>
          <input
            style={styles.input}
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="button" style={styles.button} onClick={!loading ? handleSignup : null} disabled={loading}>
            {loading ? <div style={styles.spinner}></div> : "Submit"}
          </button>
          <div style={styles.errorMessageContainer}>
            {error && <p style={styles.errorMessage}>{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupScreen;
