import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainScreen from './screens/MainScreen';
import LoginScreen from './screens/LoginScreen';
import SignupScreen from './screens/SignupScreen';
import UserScreen from './screens/UserScreen';
import CafeScreen from './screens/CafeScreen';
import CorporateScreen from './screens/CorporateScreen';
import CafeRegistrationScreen from './screens/CafeRegistrationScreen';
import CorporateRegistrationScreen from './screens/CorporateRegistrationScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import HelpScreen from './screens/HelpScreen';
import CafeSelectionScreen from './screens/CafeSelectionScreen';
import CorporateSelectionScreen from './screens/CorporateSelectionScreen';
import AccountScreen from './screens/AccountScreen';
import InviteLoginPage from './screens/InviteLoginPage'; // Import InviteLoginPage
import InviteSignupPage from './screens/InviteSignupPage'; // Import InviteSignupPage
import { UserProvider } from './context/UserContext'; // Import UserProvider
import './styles/styles.css'; // Import global styles

const App = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          {/* MainScreen as the default route */}
          <Route path="/" element={<MainScreen />} />

          {/* Login and Signup routes */}
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/caferegistration" element={<CafeRegistrationScreen />} />
          <Route path="/corporateregistration" element={<CorporateRegistrationScreen />} />

          {/* Other routes */}
          <Route path="/user" element={<UserScreen />} />
          <Route path="/cafe/:id" element={<CafeScreen />} />
          <Route path="/corporate/:id" element={<CorporateScreen />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyScreen />} />
          <Route path="/help" element={<HelpScreen />} />
          <Route path="/cafes" element={<CafeSelectionScreen />} />
          <Route path="/corporates" element={<CorporateSelectionScreen />} />
          <Route path="/account" element={<AccountScreen />} />


          {/* Invite routes */}
          <Route path="/invite/login" element={<InviteLoginPage />} />
          <Route path="/invite/signup" element={<InviteSignupPage />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
