import React from 'react';

const Container = ({ children }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'white',
      width: '100%',
      maxWidth: '400px',
    },
  };

  return <div style={styles.container}>{children}</div>;
};

export default Container;
