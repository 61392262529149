import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loginUser } from '../services/apiService';
import { addItem } from '../services/cosmosdbService';

const InviteLoginPage = () => {
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showForm, setShowForm] = useState(true); // State to toggle the form visibility

  const email = new URLSearchParams(location.search).get('email') || '';
  const corporateId = new URLSearchParams(location.search).get('corporateId') || '';

  const handleLogin = async () => {
    setLoading(true);
    setError('');
    setSuccessMessage('');

    if (!password) {
      setError('Password is required.');
      setLoading(false);
      return;
    }

    try {
      // Step 1: Log in the user
      const userData = await loginUser({ email, password });

      // Step 2: Link the user to the corporate account
      const corporateAssignment = {
        id: Date.now().toString(), // Generate a unique ID
        corporateId, // Use the corporate ID from the invite link
        userId: userData.userId, // Use the userId returned from login
        role: 'staff', // Default role for linked users
      };

      await addItem('brewserdb', 'corporate_assignments', corporateAssignment);

      setSuccessMessage('You have successfully logged in and been linked to the corporate account.');
      setShowForm(false); // Hide the form on success
    } catch (error) {
      console.error('Error logging in and linking user:', error);
      setError(error.message || 'Invalid email or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f9f9f9',
      padding: '20px',
    },
    form: {
      backgroundColor: '#ffffff',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
    },
    title: {
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '20px',
    },
    input: {
      width: '100%',
      padding: '12px',
      marginBottom: '15px',
      borderRadius: '5px',
      border: '1px solid #ddd',
      fontSize: '16px',
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#ff7f00',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      transition: 'background-color 0.3s',
    },
    buttonHover: {
      backgroundColor: '#e06b00',
    },
    successMessage: {
      color: 'green',
      backgroundColor: '#e8f5e9',
      border: '1px solid #66bb6a',
      padding: '10px',
      borderRadius: '5px',
      textAlign: 'center',
      marginBottom: '15px',
    },
    errorMessage: {
      color: '#b71c1c',
      backgroundColor: '#ffebee',
      border: '1px solid #f44336',
      padding: '10px',
      borderRadius: '5px',
      textAlign: 'center',
      marginBottom: '15px',
    },
  };

  return (
    <div style={styles.container}>
      {showForm ? (
        <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
          <h1 style={styles.title}>Log In</h1>
          {error && <p style={styles.errorMessage}>{error}</p>}
          {successMessage && <p style={styles.successMessage}>{successMessage}</p>}
          <input
            style={styles.input}
            type="email"
            value={email}
            disabled
            placeholder="Email"
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button
            style={styles.button}
            onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? 'Logging In...' : 'Log In'}
          </button>
        </form>
      ) : (
        <div style={styles.successMessage}>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default InviteLoginPage;
