import React, { useState, useContext } from 'react';
import { updatePassword } from '../services/apiService'; // Import the API service
import Screen from '../components/Screen'; // Screen component
import { UserContext } from '../context/UserContext'; // Access user data

const AccountScreen = () => {
    const { user } = useContext(UserContext); // Get user details from context
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const styles = {
        container: {
            padding: '20px',
            maxWidth: '600px',
            margin: '0 auto',
        },
        title: {
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '20px',
            textAlign: 'center',
        },
        formGroup: {
            marginBottom: '15px',
        },
        label: {
            display: 'block',
            marginBottom: '5px',
            fontSize: '16px',
        },
        input: {
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            border: '1px solid #ddd',
            borderRadius: '5px',
        },
        button: {
            padding: '10px',
            fontSize: '16px',
            backgroundColor: '#F57F17',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            marginTop: '10px',
            width: '100%',
        },
        error: {
            color: '#b71c1c',
            marginBottom: '10px',
        },
        success: {
            color: 'green',
            marginBottom: '10px',
        },
    };

    const handlePasswordChange = async () => {
        setLoading(true);
        setError('');
        setSuccessMessage('');

        if (newPassword !== confirmPassword) {
            setError('New passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            // Call the API to update the password
            await updatePassword(user.email, oldPassword, newPassword); // Explicitly pass the email
            setSuccessMessage('Password updated successfully.');
        } catch (error) {
            setError(error.message || 'Failed to update password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Screen title="Change Password">
            <div style={styles.container}>
                <h1 style={styles.title}>Change Password</h1>

                {error && <p style={styles.error}>{error}</p>}
                {successMessage && <p style={styles.success}>{successMessage}</p>}

                <div style={styles.formGroup}>
                    <label style={styles.label} htmlFor="oldPassword">
                        Old Password
                    </label>
                    <input
                        style={styles.input}
                        id="oldPassword"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label} htmlFor="newPassword">
                        New Password
                    </label>
                    <input
                        style={styles.input}
                        id="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>

                <div style={styles.formGroup}>
                    <label style={styles.label} htmlFor="confirmPassword">
                        Confirm New Password
                    </label>
                    <input
                        style={styles.input}
                        id="confirmPassword"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>

                <button
                    style={styles.button}
                    onClick={handlePasswordChange}
                    disabled={loading}
                >
                    {loading ? 'Updating...' : 'Update Password'}
                </button>
            </div>
        </Screen>
    );
};

export default AccountScreen;
