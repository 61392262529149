import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const PrivacyPolicyScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="page-container">
      <header className="navbar">
        <h1 className="logo">Brewser</h1>
        <nav className="nav-links">
          <a href="#home" onClick={() => navigate('/')}>Home</a>
          <a href="#help" onClick={() => navigate('/')}>Help</a>
          <a href="#login" onClick={() => navigate('/login')}>Login</a>
          <a href="#signup" onClick={() => navigate('/signup')}>Signup</a>
        </nav>
      </header>

      <div style={{
        backgroundColor: '#fff',
        maxWidth: '800px',
        margin: '20px auto',
        padding: '40px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}>
        <h1>Privacy Policy</h1>
        <p>
          Welcome to Brewser! Your privacy is very important to us, and we are committed to protecting
          it. This Privacy Policy explains what personal information we collect, how we use it, and
          the choices you have regarding your information.
        </p>

        <h2>Information We Collect</h2>
        <p>
          We collect information to provide better services to our users. The type of information we
          collect is limited to personal identification information (name, email address, etc.).
        </p>

        <h2>How We Use Your Information</h2>
        <p>
          We use the information we collect to provide and improve our services.
        </p>

        <h2>Your Choices and Rights</h2>
        <p>
          You have the right to access, modify, or delete your personal information. You can request your personal information by emailing get@more.org.nz with details of your request.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. We encourage you to review this page
          periodically for any changes.
        </p>

        <p>Last updated: 29/10/2024</p>
      </div>
    </div>
  );
};

export default PrivacyPolicyScreen;
