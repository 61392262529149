import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { addItem, fetchItems, deleteItem } from '../services/cosmosdbService';
import QRCode from 'qrcode';
import { EmailClient, EmailMessage } from '@azure/communication-email';

const CorporateUsers = () => {
  const [email, setEmail] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [corporateId, setCorporateId] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchCorporateData = async () => {
      if (!user) {
        console.log('No user data found in context');
        setLoading(false);
        return;
      }
      console.log('Fetching corporate assignments for user ID:', user.userId);

      try {
        const assignments = await fetchItems(
          'brewserdb',
          'corporates',
          "SELECT * FROM c WHERE c.userId = @userId",
          [{ name: '@userId', value: user.userId }]
        );

        if (assignments.length > 0) {
          const corporateId = assignments[0].id;
          setCorporateId(corporateId);
          setCompanyName(assignments[0].companyName);
          console.log('Corporate ID found:', corporateId);

          const staffAssignments = await fetchItems(
            'brewserdb',
            'corporate_assignments',
            "SELECT * FROM c WHERE c.corporateId = @corporateId",
            [{ name: '@corporateId', value: corporateId }]
          );

          const staffDetails = await Promise.all(
            staffAssignments.map(async (assignment) => {
              const userDetails = await fetchItems(
                'brewserdb',
                'accounts',
                "SELECT * FROM c WHERE c.id = @userId",
                [{ name: '@userId', value: assignment.userId }]
              );
              if (userDetails.length > 0) {
                return {
                  ...assignment,
                  firstName: userDetails[0].firstName,
                  lastName: userDetails[0].lastName,
                  email: userDetails[0].email, // Include email field
                };
              }
              return assignment;
            })
          );

          setStaffList(staffDetails);
        } else {
          setError('You do not have an assigned corporate account.');
        }
      } catch (err) {
        console.error('Failed to retrieve corporate information:', err);
        setError('Failed to retrieve corporate information');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.userId) {
      fetchCorporateData();
    } else {
      console.log('User context not ready or user ID missing');
      setLoading(false);
    }
  }, [user]);

  const connectionString = 'endpoint=https://brewseremail.australia.communication.azure.com/;accesskey=67UZalLTMkiqviWiSE5l7jM75minYf54POCTX13sBjZMaeisGQ0GJQQJ99AKACULyCpJ9B2EAAAAAZCSpcKj'; // Replace with your ACS connection string
  const verifiedSenderEmail = 'DoNotReply@1aa4c387-9cbc-4652-8591-e89322eb5489.azurecomm.net';

  const handleInvite = async () => {
    // Helper function to validate email format
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
      return emailRegex.test(email);
    };
  
    if (!email || !corporateId) {
      setError('Please enter at least one valid email address and ensure the corporate ID is available.');
      return;
    }
  
    setError('');
    setSuccessMessage('');
    setLoading(true);
  
    try {
      // Split the input string into an array of emails and trim spaces
      const emailList = email.split(',').map((email) => email.trim());
  
      if (emailList.some((email) => !validateEmail(email))) {
        setError('One or more email addresses are invalid.');
        setLoading(false);
        return;
      }
  
      const failedEmails = [];
  
      // Loop through each email and send an invite
      for (const singleEmail of emailList) {
        try {
          const inviteToken = Date.now().toString();
  
          // Logic to determine whether this is a login or signup invite
          // Check if the user already exists in the system
          const existingUsers = await fetchItems(
            'brewserdb',
            'accounts',
            "SELECT * FROM c WHERE c.email = @userEmail",
            [{ name: '@userEmail', value: singleEmail }]
          );
  
          const isSignup = existingUsers.length === 0; // No user found, treat as signup
  
          // Generate the invite URL
          const inviteUrl = `${window.location.origin}/invite/${isSignup ? 'signup' : 'login'}?token=${inviteToken}&email=${encodeURIComponent(singleEmail)}&corporateId=${corporateId}`;
  
          // Save the invite in the database
          await addItem('brewserdb', 'invitations', {
            token: inviteToken,
            email: singleEmail,
            corporateId,
            status: 'pending',
            inviteType: isSignup ? 'signup' : 'login', // Track invite type
          });
  
          // Initialize the Email Client
          const emailClient = new EmailClient(connectionString);
  
          // Prepare the email message
          const emailMessage = {
            senderAddress: verifiedSenderEmail,
            content: {
              subject: 'You’ve been invited to join a corporate account on Brewser',
              plainText: `You’ve been invited to join the corporate account on Brewser. Use the following link to accept your invitation: ${inviteUrl}`,
              html: `
                <h1>Welcome to Brewser</h1>
                <p>You’ve been invited to join the corporate account.</p>
                <p>
                  Click the link below to accept your invitation:
                  <a href="${inviteUrl}">${inviteUrl}</a>
                </p>
              `,
            },
            recipients: {
              to: [
                {
                  address: singleEmail,
                },
              ],
            },
          };
  
          // Send the email using beginSend
          const poller = await emailClient.beginSend(emailMessage);
          await poller.pollUntilDone();
        } catch (error) {
          console.error(`Failed to send invite to ${singleEmail}:`, error);
          failedEmails.push(singleEmail);
        }
      }
  
      if (failedEmails.length > 0) {
        setError(`Failed to send invites to the following emails: ${failedEmails.join(', ')}`);
      } else {
        setSuccessMessage('Invites sent successfully to all provided email addresses.');
      }
    } catch (error) {
      console.error('Error during invite process:', error);
      setError('An error occurred while sending invites. Please try again.');
    } finally {
      setLoading(false);
      setEmail(''); // Clear the input field
    }
  };

  const handleRemove = async (assignmentId) => {
    try {
      await deleteItem('brewserdb', 'corporate_assignments', assignmentId);
      setStaffList((prevList) => prevList.filter((staff) => staff.id !== assignmentId));
      setSuccessMessage('Staff member removed successfully.');
    } catch (error) {
      console.error('Failed to remove staff member:', error);
      setError('Failed to remove staff member. Please try again.');
    }
  };

  const styles = {
    container: {
      width: '100%',
    },
    controlsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    input: {
      width: '40%',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #DDDDDD',
    },
    button: {
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#F57F17',
      color: '#FFF',
      border: 'none',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
    },
    th: {
      borderBottom: '2px solid #DDDDDD',
      padding: '10px',
      textAlign: 'left',
      backgroundColor: '#F5F5F5',
    },
    td: {
      borderBottom: '1px solid #DDDDDD',
      padding: '10px',
    },
    nameColumn: {
      width: 'auto', // Let Name column take remaining space
    },
    emailColumn: {
      width: '250px', // Fixed width for Email
      textAlign: 'left',
    },
    roleColumn: {
      width: '150px', // Fixed width for Role
      textAlign: 'center',
    },
    actionsColumn: {
      width: '100px', // Fixed width for Actions
      textAlign: 'center',
    },
    removeButton: {
      backgroundColor: '#b71c1c',
      color: '#FFF',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: 'none',
    },
  };

  const filteredStaffList = staffList.filter((staff) =>
    (staff.firstName?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
    (staff.lastName?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
    (staff.email?.toLowerCase() || "").includes(searchTerm.toLowerCase()) ||
    (staff.role?.toLowerCase() || "").includes(searchTerm.toLowerCase())
  );
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.container}>

      {error && <p style={{ color: '#b71c1c' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      <div style={styles.controlsContainer}>
        <input
          style={styles.input}
          type="text"
          placeholder="Search staff..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div>
          <input
            style={styles.input}
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button style={styles.button} onClick={handleInvite}>
            Invite Staff
          </button>
        </div>
      </div>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={{ ...styles.th, ...styles.nameColumn }}>Name</th>
            <th style={{ ...styles.th, ...styles.emailColumn }}>Email</th>
            <th style={{ ...styles.th, ...styles.roleColumn }}>Role</th>
            <th style={{ ...styles.th, ...styles.actionsColumn }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredStaffList.map((staff) => (
            <tr key={staff.id}>
              <td style={{ ...styles.td, ...styles.nameColumn }}>
                {staff.firstName} {staff.lastName}
              </td>
              <td style={{ ...styles.td, ...styles.emailColumn }}>{staff.email}</td>
              <td style={{ ...styles.td, ...styles.roleColumn }}>{staff.role}</td>
              <td style={{ ...styles.td, ...styles.actionsColumn }}>
                <button
                  style={styles.removeButton}
                  onClick={() => handleRemove(staff.id)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CorporateUsers;
