import React, { useState, useEffect } from 'react';
import { fetchItems } from '../services/cosmosdbService';

const CorporateScans = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 50;

  useEffect(() => {
    const fetchScans = async () => {
      try {
        const scanData = await fetchItems('brewserdb', 'scans', "SELECT * FROM c");

        const enrichedScans = await Promise.all(
          scanData.map(async (scan) => {
            const userData = await fetchItems(
              'brewserdb',
              'accounts',
              "SELECT * FROM c WHERE c.id = @userId",
              [{ name: '@userId', value: scan.userId }]
            );

            if (userData.length > 0) {
              const user = userData[0];
              return {
                ...scan,
                name: `${user.firstName} ${user.lastName}`,
              };
            }

            return {
              ...scan,
              name: 'Unknown User',
            };
          })
        );

        // Sort scans by timestamp in descending order
        enrichedScans.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

        setScans(enrichedScans);
      } catch (err) {
        console.error('Failed to retrieve scan data:', err);
        setError('Failed to retrieve scan data.');
      } finally {
        setLoading(false);
      }
    };

    fetchScans();
  }, []);

  const filteredScans = scans.filter((scan) =>
    (scan.name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredScans.length / resultsPerPage);
  const paginatedScans = filteredScans.slice(
    (currentPage - 1) * resultsPerPage,
    currentPage * resultsPerPage
  );

  const styles = {
    container: {
      width: '100%',
    },
    controlsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    input: {
      width: '40%',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #DDDDDD',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',
    },
    th: {
      borderBottom: '2px solid #DDDDDD',
      padding: '10px',
      textAlign: 'left',
      backgroundColor: '#F5F5F5',
    },
    td: {
      borderBottom: '1px solid #DDDDDD',
      padding: '10px',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    pageButton: {
      padding: '5px 10px',
      margin: '0 5px',
      borderRadius: '5px',
      backgroundColor: '#F57F17',
      color: '#FFF',
      border: 'none',
      cursor: 'pointer',
    },
    disabledButton: {
      backgroundColor: '#DDD',
      cursor: 'not-allowed',
    },
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.container}>
      {error && <p style={{ color: '#b71c1c' }}>{error}</p>}

      <div style={styles.controlsContainer}>
        <input
          style={styles.input}
          type="text"
          placeholder="Search scans..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.th}>Name</th>
            <th style={styles.th}>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {paginatedScans.map((scan) => (
            <tr key={scan.id}>
              <td style={styles.td}>{scan.name}</td>
              <td style={styles.td}>{new Date(scan.timestamp).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={styles.pagination}>
        <button
          style={{
            ...styles.pageButton,
            ...(currentPage === 1 && styles.disabledButton),
          }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          style={{
            ...styles.pageButton,
            ...(currentPage === totalPages && styles.disabledButton),
          }}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CorporateScans;