import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Container from '../components/Container';
import { fetchItems } from '../services/cosmosdbService';
import { UserContext } from '../context/UserContext';

const UserScreen = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('cafes'); // "cafes" is selected by default
  const [cafes, setCafes] = useState([]);
  const [corporates, setCorporates] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const cafeQuery = "SELECT * FROM c WHERE c.userId = @userId";
        const corporateQuery = "SELECT * FROM c WHERE c.userId = @userId";
        const parameters = [{ name: "@userId", value: user.userId }];

        // Fetch cafes and corporates data for the logged-in user
        const [fetchedCafes, fetchedCorporates] = await Promise.all([
          fetchItems('brewserdb', 'cafe_assignments', cafeQuery, parameters),
          fetchItems('brewserdb', 'corporates', corporateQuery, parameters),
        ]);

        setCafes(
          await Promise.all(
            fetchedCafes.map(async (assignment) => {
              const [cafeDetails] = await fetchItems(
                'brewserdb',
                'cafes',
                "SELECT * FROM c WHERE c.id = @cafeId",
                [{ name: '@cafeId', value: assignment.cafeId }]
              );
              return cafeDetails;
            })
          )
        );

        setCorporates(fetchedCorporates);
      } catch (error) {
        console.error("Error fetching data:", error); // Log errors during fetch
      } finally {
        setLoading(false);
      }
    };

    if (user?.userId) {
      fetchData();
    } else {
      console.warn('User ID is not available.'); // Log if user ID is missing
    }
  }, [user.userId]);

  const handleNavigateCorporate = (corporateId) => {
    navigate(`/corporate/${corporateId}`); // Pass `corporateId` to CorporateScreen
  };

  const styles = {
    pageWrapper: {
      minHeight: '100vh',
      paddingTop: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    tabs: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    tab: {
      padding: '10px 20px',
      cursor: 'pointer',
      borderBottom: '2px solid transparent',
    },
    activeTab: {
      borderBottom: '2px solid #ff7f00',
      fontWeight: 'bold',
    },
    button: {
      padding: '15px 30px',
      fontSize: '18px',
      color: 'white',
      backgroundColor: '#ff7f00',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      marginBottom: '20px',
      width: '100%',
      maxWidth: '250px',
      textAlign: 'center',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    listTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '10px',
      textAlign: 'center',
    },
    list: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      width: '100%',
      maxWidth: '600px',
    },
    listItem: {
      padding: '10px',
      borderBottom: '1px solid #ccc',
      cursor: 'pointer',
      color: '#333',
    },
  };

  return (
    <div style={styles.pageWrapper}>
      <Header />
      <Container>
        <div style={styles.tabs}>
          <div
            style={{ ...styles.tab, ...(activeTab === 'cafes' ? styles.activeTab : {}) }}
            onClick={() => setActiveTab('cafes')}
          >
            Cafes
          </div>
          <div
            style={{ ...styles.tab, ...(activeTab === 'corporates' ? styles.activeTab : {}) }}
            onClick={() => setActiveTab('corporates')}
          >
            Corporate Accounts
          </div>
        </div>

        {loading && <p>Loading...</p>}
        {!loading && activeTab === 'corporates' && (
          <>
            <div style={styles.listTitle}>Your Corporate Accounts</div>
            <ul style={styles.list}>
              {corporates.map((corporate) => (
                <li
                  key={corporate.id}
                  style={styles.listItem}
                  onClick={() => handleNavigateCorporate(corporate.id)}
                >
                  {corporate?.companyName || 'Unnamed Corporate Account'}
                </li>
              ))}
            </ul>
          </>
        )}
      </Container>
    </div>
  );
};

export default UserScreen;
