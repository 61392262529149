import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const HelpScreen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const subject = encodeURIComponent('Help Request from Brewser');
    const body = encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`
    );

    window.location.href = `mailto:get@more.org.nz?subject=${subject}&body=${body}`;
  };

  return (
    <div className="page-container">
      <header className="navbar">
        <h1 className="logo">Brewser</h1>
        <nav className="nav-links">
          <a href="#home" onClick={() => navigate('/')}>Home</a>
          <a href="#help" onClick={() => navigate('/')}>Help</a>
          <a href="#login" onClick={() => navigate('/login')}>Login</a>
          <a href="#signup" onClick={() => navigate('/signup')}>Signup</a>
        </nav>
      </header>

      <div style={{
        backgroundColor: '#fff',
        maxWidth: '800px',
        margin: '20px auto',
        padding: '40px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
      }}>
        <h2>Contact Support</h2>
        <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
          <div>
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', margin: '8px 0' }}
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', margin: '8px 0' }}
            />
          </div>
          <div>
            <label>Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              style={{ width: '100%', padding: '8px', margin: '8px 0' }}
            />
          </div>
          <button type="submit" style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default HelpScreen;
