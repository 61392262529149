import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { signupUser } from '../services/apiService';
import { addItem } from '../services/cosmosdbService';
import iosStoreIcon from '../assets/ios_store.png'; // Import the app store icon

const InviteSignupPage = () => {
  const location = useLocation();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(new URLSearchParams(location.search).get('email') || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showForm, setShowForm] = useState(true); // State to toggle the form visibility

  const corporateId = new URLSearchParams(location.search).get('corporateId') || '';

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = async () => {
    setLoading(true);
    setError('');
    setSuccessMessage('');

    if (!firstName || !lastName || !email || !password || !confirmPassword) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      // Step 1: Create the user
      const newUser = { email, password, firstName, lastName };
      const userData = await signupUser(newUser);

      // Step 2: Link the user to the corporate account
      const corporateAssignment = {
        id: Date.now().toString(), // Generate a unique ID
        corporateId, // Use the corporate ID from the invite link
        userId: userData.userId, // Use the userId returned from signup
        role: 'staff', // Default role for new users
      };

      await addItem('brewserdb', 'corporate_assignments', corporateAssignment);

      setSuccessMessage('Your account has been created and linked to the corporate account successfully.');
      setShowForm(false); // Hide the form on success
    } catch (error) {
      console.error('Error signing up and linking user:', error);
      setError(error.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f9f9f9',
      padding: '20px',
    },
    form: {
      backgroundColor: '#ffffff',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
    },
    title: {
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '20px',
    },
    input: {
      width: '100%',
      padding: '12px',
      marginBottom: '15px',
      borderRadius: '5px',
      border: '1px solid #ddd',
      fontSize: '16px',
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#ff7f00',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: 'bold',
      transition: 'background-color 0.3s',
    },
    buttonHover: {
      backgroundColor: '#e06b00',
    },
    successMessage: {
      color: 'green',
      backgroundColor: '#e8f5e9',
      border: '1px solid #66bb6a',
      padding: '10px',
      borderRadius: '5px',
      textAlign: 'center',
      marginBottom: '15px',
    },
    errorMessage: {
      color: '#b71c1c',
      backgroundColor: '#ffebee',
      border: '1px solid #f44336',
      padding: '10px',
      borderRadius: '5px',
      textAlign: 'center',
      marginBottom: '15px',
    },
    appStoreContainer: {
      textAlign: 'center',
    },
    appStoreButton: {
      display: 'inline-block',
      marginTop: '15px',
    },
    appStoreImage: {
      width: '150px',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.container}>
      {showForm ? (
        <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
          <h1 style={styles.title}>Sign Up</h1>
          {error && <p style={styles.errorMessage}>{error}</p>}
          {successMessage && <p style={styles.successMessage}>{successMessage}</p>}
          <input
            style={styles.input}
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button
            style={styles.button}
            onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
            onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
            onClick={handleSignup}
            disabled={loading}
          >
            {loading ? 'Signing Up...' : 'Sign Up'}
          </button>
        </form>
      ) : (
        <div style={styles.appStoreContainer}>
          <p style={styles.successMessage}>{successMessage}</p>
          <p>Download the Brewser app to get started:</p>
          <a href="https://apps.apple.com/nz/app/brewser/id6737429504" style={styles.appStoreButton}>
            <img src={iosStoreIcon} alt="Download on the App Store" style={styles.appStoreImage} />
          </a>
        </div>
      )}
    </div>
  );
};

export default InviteSignupPage;
