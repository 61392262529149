import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/apiService';
import { UserContext } from '../context/UserContext';
import Header from '../components/Header';
import Container from '../components/Container';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { login } = useContext(UserContext);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            // Authenticate the user
            const userData = await loginUser({ email, password });
            login(userData); // Store token and userId in context or storage

            // Navigate to the UserScreen on successful login
            navigate('/cafes');
        } catch (error) {
            console.error('Error logging in:', error);
            setError(error.message || 'An error occurred during login. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const styles = {
        pageContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            paddingTop: '80px',
        },
        title: {
            fontSize: '24px',
            marginBottom: '20px',
            color: '#ff7f00',
        },
        input: {
            width: '300px',
            padding: '10px',
            marginBottom: '10px',
            fontSize: '16px',
            borderRadius: '4px',
            border: '1px solid #ddd',
        },
        button: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '300px',
            height: '40px',
            cursor: 'pointer',
            backgroundColor: '#ff7f00',
            color: 'white',
            fontWeight: 'bold',
            border: 'none',
            borderRadius: '4px',
            marginTop: '10px',
        },
        errorMessageContainer: {
            height: '40px',
            marginTop: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '300px',
        },
        errorMessage: {
            color: '#b71c1c',
            backgroundColor: '#ffebee',
            border: '1px solid #f44336',
            padding: '10px',
            borderRadius: '4px',
            textAlign: 'center',
            fontSize: '0.9rem',
            width: '100%',
        },
    };

    return (
        <div style={styles.pageContainer}>
            <Header />
            <Container>
                <form onSubmit={handleLogin} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h1 style={styles.title}>Login</h1>
                    {error && <div style={styles.errorMessageContainer}><p style={styles.errorMessage}>{error}</p></div>}
                    <input
                        style={styles.input}
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        style={styles.input}
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit" style={styles.button} disabled={loading}>
                        {loading ? 'Logging in...' : 'Submit'}
                    </button>
                </form>
            </Container>
        </div>
    );
};

export default LoginScreen;
