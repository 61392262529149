import React from 'react';

const Menu = ({ menuItems, activeModule, onModuleSelect }) => {
  const styles = {
    menuContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '10px 20px',
      borderBottom: '1px solid #DDD',
    },
    menuItem: {
      marginRight: '20px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: activeModule ? 'bold' : 'normal',
      color: '#F57F17', // Default color
      borderBottom: activeModule ? '2px solid #F57F17' : '2px solid transparent',
    },
  };

  return (
    <div style={styles.menuContainer}>
      {menuItems.map((menuItem) => (
        <span
          key={menuItem.key}
          style={{
            ...styles.menuItem,
            ...(activeModule === menuItem.key && { fontWeight: 'bold', borderBottom: '2px solid #F57F17' }),
          }}
          onClick={() => onModuleSelect(menuItem.key)}
        >
          {menuItem.label}
        </span>
      ))}
    </div>
  );
};

export default Menu;
