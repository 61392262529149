import { CosmosClient } from '@azure/cosmos';

// Retrieve endpoint and key from environment variables
const endpoint = process.env.REACT_APP_COSMOSDB_ENDPOINT;
const key = process.env.REACT_APP_COSMOSDB_KEY;

// Check if the environment variables are correctly loaded
if (!endpoint || !key) {
  throw new Error("Cosmos DB endpoint or key is not defined in environment variables.");
}

const client = new CosmosClient({ endpoint, key });

/**
 * Get or create a container within a specified database
 * @param {string} databaseId - The ID of the database
 * @param {string} containerId - The ID of the container
 * @param {string} partitionKey - The partition key path (default is '/id')
 * @returns {Promise<object>} - The container instance
 */
async function getContainer(databaseId, containerId, partitionKey = '/id') {
  const { database } = await client.databases.createIfNotExists({ id: databaseId });
  const { container } = await database.containers.createIfNotExists({
    id: containerId,
    partitionKey: { paths: [partitionKey] }
  });
  return container;
}

/**
 * Fetch items from a specified container with an optional query
 * @param {string} databaseId - The database ID
 * @param {string} containerId - The container ID
 * @param {string} query - Optional query string for filtering results
 * @param {Array} parameters - Optional parameters for the query
 * @returns {Promise<Array>} - Array of items
 */
export const fetchItems = async (databaseId, containerId, query = "SELECT * FROM c", parameters = []) => {
  try {
    const container = await getContainer(databaseId, containerId);
    const querySpec = { query, parameters };
    const { resources: items } = await container.items.query(querySpec).fetchAll();
    return items;
  } catch (error) {
    console.error(`Error fetching items from ${containerId}:`, error);
    throw error;
  }
};

/**
 * Add an item to a specified container
 * @param {string} databaseId - The database ID
 * @param {string} containerId - The container ID
 * @param {object} item - The item to add
 * @returns {Promise<object>} - The created item
 */
export const addItem = async (databaseId, containerId, item) => {
  try {
    const container = await getContainer(databaseId, containerId);
    const { resource: createdItem } = await container.items.create(item);
    return createdItem;
  } catch (error) {
    console.error(`Error creating item in ${containerId}:`, error);
    throw error;
  }
};

/**
 * Delete an item from a specified container by ID
 * @param {string} databaseId - The database ID
 * @param {string} containerId - The container ID
 * @param {string} id - The ID of the item to delete
 * @returns {Promise<object>} - The deleted item
 */
export const deleteItem = async (databaseId, containerId, id) => {
  try {
    const container = await getContainer(databaseId, containerId);
    const { resource } = await container.item(id, id).delete();
    return resource;
  } catch (error) {
    console.error(`Error deleting item from ${containerId}:`, error);
    throw error;
  }
};

/**
 * Update or upsert an item in a specified container
 * @param {string} databaseId - The database ID
 * @param {string} containerId - The container ID
 * @param {object} updatedData - The updated item data
 * @returns {Promise<object>} - The upserted item
 */
export const upsertItem = async (databaseId, containerId, updatedData) => {
  try {
    const container = await getContainer(databaseId, containerId);
    const { resource: upsertedItem } = await container.items.upsert(updatedData);
    return upsertedItem;
  } catch (error) {
    console.error(`Error upserting item in ${containerId}:`, error);
    throw error;
  }
};
