import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Screen from '../components/Screen';
import CorporateDashboard from '../modules/CorporateDashboard';
import CorporateUsers from '../modules/CorporateUsers';
import CorporateScans from '../modules/CorporateScans';

const CorporateScreen = () => {
  const { state } = useLocation(); // Access the state from navigation
  const { corporateId } = useParams(); // Retrieve corporateId from the URL
  const corporateName = state?.corporateName || 'Corporate Account'; // Fallback name

  const menuItems = [
    {
      key: 'staff',
      label: 'Staff',
      component: CorporateUsers, // Renders CorporateUsers module
    },
    {
      key: 'scans',
      label: 'Scans',
      component: CorporateScans, // Renders CorporateUsers module
    },
  ];

  return <Screen title={corporateName} menuItems={menuItems} />;
};

export default CorporateScreen;
