import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Container from '../components/Container';
import { fetchItems } from '../services/cosmosdbService';
import { UserContext } from '../context/UserContext';

const CorporateSelectionScreen = () => {
  const [corporates, setCorporates] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCorporates = async () => {
      setLoading(true);
      try {
        const corporateQuery = "SELECT * FROM c WHERE c.userId = @userId";
        const parameters = [{ name: "@userId", value: user.userId }];

        const fetchedCorporates = await fetchItems('brewserdb', 'corporates', corporateQuery, parameters);
        setCorporates(fetchedCorporates);
      } catch (error) {
        console.error("Error fetching corporates:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.userId) {
      fetchCorporates();
    }
  }, [user.userId]);

  const handleNavigateCorporate = (corporateId, corporateName) => {
    navigate(`/corporate/${corporateId}`, { state: { corporateName } }); // Pass corporateName in the state
  };

  const styles = {
    pageWrapper: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Horizontally center content
      paddingTop: '100px',
      textAlign: 'center',
    },
    listTitle: { fontSize: '20px', fontWeight: 'bold', marginBottom: '10px' },
    list: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      maxWidth: '600px',
    },
    listItem: {
      padding: '10px',
      borderBottom: '1px solid #ccc',
      cursor: 'pointer',
      color: '#333',
    },
  };

  return (
    <div style={styles.pageWrapper}>
      <Header />
      <Container>
        {loading && <p>Loading corporate accounts...</p>}
        {!loading && corporates.length > 0 ? (
          <>
            <div style={styles.listTitle}>Your Corporate Accounts</div>
            <ul style={styles.list}>
              {corporates.map((corporate) => (
                <li
                  key={corporate.id}
                  style={styles.listItem}
                  onClick={() => handleNavigateCorporate(corporate.id, corporate.companyName)}
                >
                  {corporate?.companyName || 'Unnamed Corporate Account'}
                </li>
              ))}
            </ul>
          </>
        ) : (
          !loading && <p>No corporate accounts assigned to your account.</p>
        )}
      </Container>
    </div>
  );
};

export default CorporateSelectionScreen;
