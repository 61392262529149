import React, { useState, useEffect } from 'react';
import { fetchItems, upsertItem, deleteItem } from '../services/cosmosdbService';

const CafeCorporateAccounts = ({ cafeId, cafeLinkingCode }) => {
  const [linkedCorporateAccounts, setLinkedCorporateAccounts] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const loadLinkedCorporateAccounts = async () => {
      if (cafeId) {
        try {
          // Fetch partnerships for the current cafe
          const partnerships = await fetchItems(
            'brewserdb',
            'corporate_cafe_partnerships',
            "SELECT * FROM c WHERE c.cafeId = @cafeId",
            [{ name: '@cafeId', value: cafeId }]
          );

          // Fetch company names for each linked corporate account
          const linkedAccountsWithNames = await Promise.all(
            partnerships.map(async (partnership) => {
              const corporateDetails = await fetchItems(
                'brewserdb',
                'corporates',
                "SELECT * FROM c WHERE c.id = @corporateId",
                [{ name: '@corporateId', value: partnership.corporateId }]
              );
              return {
                ...partnership,
                companyName: corporateDetails[0]?.companyName || 'Unknown Company',
              };
            })
          );

          setLinkedCorporateAccounts(linkedAccountsWithNames);
        } catch (err) {
          console.error('Failed to load linked corporate accounts:', err);
          setError('Failed to retrieve linked corporate accounts.');
        }
      }
    };

    loadLinkedCorporateAccounts();
  }, [cafeId]);

  const handleApprove = async (partnershipId) => {
    try {
      await upsertItem('brewserdb', 'corporate_cafe_partnerships', {
        id: partnershipId,
        status: 'linked',
      });
      setLinkedCorporateAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.id === partnershipId ? { ...account, status: 'linked' } : account
        )
      );
      setSuccessMessage('Partnership approved successfully.');
    } catch (error) {
      console.error('Error approving partnership:', error);
      setError('Failed to approve partnership.');
    }
  };

  const handleReject = async (partnershipId) => {
    try {
      await deleteItem('brewserdb', 'corporate_cafe_partnerships', partnershipId);
      setLinkedCorporateAccounts((prevAccounts) =>
        prevAccounts.filter((account) => account.id !== partnershipId)
      );
      setSuccessMessage('Partnership rejected successfully.');
    } catch (error) {
      console.error('Error rejecting partnership:', error);
      setError('Failed to reject partnership.');
    }
  };

  const handleUpdateDailyCoffees = async (partnershipId, newDailyCoffees) => {
    try {
      const partnershipItems = await fetchItems(
        'brewserdb',
        'corporate_cafe_partnerships',
        "SELECT * FROM c WHERE c.id = @partnershipId",
        [{ name: '@partnershipId', value: partnershipId }]
      );

      if (partnershipItems.length === 0) {
        throw new Error('Partnership not found.');
      }

      const partnership = partnershipItems[0];
      partnership.dailyCoffees = parseInt(newDailyCoffees, 10);

      await upsertItem('brewserdb', 'corporate_cafe_partnerships', partnership);

      setLinkedCorporateAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.id === partnershipId
            ? { ...account, dailyCoffees: partnership.dailyCoffees }
            : account
        )
      );

      setSuccessMessage('Daily coffees updated successfully.');
    } catch (error) {
      console.error('Error updating daily coffees:', error);
      setError('Failed to update daily coffees.');
    }
  };

  const styles = {
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: '50px',
    },
    formContainer: {
      padding: '40px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
      textAlign: 'center',
    },
    linkingCode: {
      fontSize: '18px',
      color: '#333',
      backgroundColor: '#f4f4f4',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    linkedCorporateList: {
      marginTop: '20px',
      textAlign: 'left',
    },
    dailyCoffeesInput: {
      width: '50px',
      marginLeft: '10px',
      padding: '5px',
    },
    unlinkButton: {
      backgroundColor: '#b71c1c',
      color: '#FFF',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: 'none',
      marginLeft: '10px',
    },
    approveButton: {
      backgroundColor: 'green',
      color: '#FFF',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
      marginLeft: '5px',
    },
    rejectButton: {
      backgroundColor: 'red',
      color: '#FFF',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
      marginLeft: '5px',
    },
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1>Manage Corporate Partnerships</h1>

        {cafeLinkingCode && (
          <div style={styles.linkingCode}>
            Cafe Linking Code: {cafeLinkingCode}
          </div>
        )}

        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}

        <h3>Linked Corporate Accounts:</h3>
        {linkedCorporateAccounts.length > 0 ? (
          linkedCorporateAccounts.map((account) => (
            <div key={account.id} style={styles.linkedCorporateList}>
              <p>
                {account.companyName} - Status: {account.status}
                {account.status === 'pending' ? (
                  <>
                    <button
                      style={styles.approveButton}
                      onClick={() => handleApprove(account.id)}
                    >
                      Approve
                    </button>
                    <button
                      style={styles.rejectButton}
                      onClick={() => handleReject(account.id)}
                    >
                      Reject
                    </button>
                  </>
                ) : (
                  <input
                    style={styles.dailyCoffeesInput}
                    type="number"
                    value={account.dailyCoffees || ''}
                    onChange={(e) =>
                      handleUpdateDailyCoffees(account.id, e.target.value)
                    }
                  />
                )}
              </p>
            </div>
          ))
        ) : (
          <p>No corporate accounts linked yet.</p>
        )}
      </div>
    </div>
  );
};

export default CafeCorporateAccounts;
