import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext'; // Import UserContext

const Header = () => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { user, logout } = useContext(UserContext); // Access user state and logout function

  const handleLogout = () => {
    logout(); // Clear user session
    navigate('/'); // Redirect to home page
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const styles = {
    headerContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      maxWidth: '1200px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      margin: '0 auto',
      zIndex: 1000,
    },
    logo: {
      fontSize: '50px',
      color: '#333',
      fontWeight: 'bold',
      fontFamily: 'Cowboy Hippie Pro, sans-serif',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
    },
    logoHover: {
      color: '#F57F17',
    },
    navLinks: {
      display: 'flex',
      alignItems: 'center',
      gap: '20px',
      flex: 1,
      justifyContent: 'center',
    },
    navLink: {
      fontSize: '20px',
      color: '#000',
      fontFamily: 'Anton, sans-serif',
      fontWeight: 'normal',
      textDecoration: 'none',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
    },
    navLinkHover: {
      color: '#F57F17',
    },
    profileContainer: {
      position: 'relative',
      cursor: 'pointer',
    },
    profileIcon: {
      fontSize: '25px',
      color: '#333',
      transition: 'color 0.3s ease',
    },
    profileIconHover: {
      color: '#F57F17',
    },
    dropdownMenu: {
      position: 'absolute',
      top: '50px',
      right: 0,
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '5px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 1001,
      minWidth: '150px',
    },
    dropdownItem: {
      padding: '10px',
      textAlign: 'left',
      fontSize: '16px',
      color: '#000',
      fontFamily: 'Anton, sans-serif',
      textDecoration: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
    },
    dropdownItemHover: {
      backgroundColor: '#f0f0f0',
    },
  };

  return (
    <div style={styles.headerContainer}>
      {/* Logo on the left */}
      <div
        style={styles.logo}
        onMouseOver={(e) => (e.target.style.color = styles.logoHover.color)}
        onMouseOut={(e) => (e.target.style.color = styles.logo.color)}
        onClick={() => navigate('/')}
      >
        Brewser
      </div>

      {/* Navigation links in the center */}
      <div style={styles.navLinks}>
        <span
          style={styles.navLink}
          onMouseOver={(e) => (e.target.style.color = styles.navLinkHover.color)}
          onMouseOut={(e) => (e.target.style.color = '#000')}
          onClick={() => navigate('/cafes')}
        >
          Cafes
        </span>
        <span
          style={styles.navLink}
          onMouseOver={(e) => (e.target.style.color = styles.navLinkHover.color)}
          onMouseOut={(e) => (e.target.style.color = '#000')}
          onClick={() => navigate('/corporates')}
        >
          Corporate Accounts
        </span>
      </div>

      {/* Show Profile Icon if User is Logged In */}
      {user && (
        <div
          style={styles.profileContainer}
          onClick={toggleDropdown}
        >
          <i
            className="bi bi-person-circle"
            style={styles.profileIcon}
            onMouseOver={(e) => (e.target.style.color = styles.profileIconHover.color)}
            onMouseOut={(e) => (e.target.style.color = styles.profileIcon.color)}
          ></i>

          {dropdownVisible && (
            <div style={styles.dropdownMenu}>
              <div
                style={styles.dropdownItem}
                onMouseOver={(e) => (e.target.style.backgroundColor = styles.dropdownItemHover.backgroundColor)}
                onMouseOut={(e) => (e.target.style.backgroundColor = '#fff')}
                onClick={handleLogout}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
