import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/mainscreen.css';
import imgCoffee from '../assets/coffee.png';
import imgIosStore from '../assets/ios_store.png';
import imgAndroidStore from '../assets/android_store.png';

const MainScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="page-container" style={{ backgroundColor: '#E1CFB9' }}>
      <header className="navbar">
        <h1 className="logo">Brewser</h1>
        <nav className="nav-links">
          <a href="#home" onClick={() => navigate('/')}>Home</a>
          <a href="#help" onClick={() => navigate('/help')}>Help</a>
          <a href="#login" onClick={() => navigate('/login')}>Login</a>
          <a href="#signup" onClick={() => navigate('/signup')}>Signup</a>
        </nav>
      </header>

      <section className="hero-section">
        <div className="content-container">
          <div className="intro-section">
            <h2 className="intro-title">
              <span className="intro-title-part1">The App that Brews</span> <span className="intro-title-part2">Loyalty!</span>
            </h2>
            <p className="intro-text">Scan. Sip. Save. Support Local.</p>
            <div className="download-buttons">
              <a href="https://apps.apple.com/nz/app/brewser/id6737429504" target="_blank" rel="noopener noreferrer">
                <img src={imgIosStore} alt="Download on the App Store" className="download-button" />
              </a>
              {/* <a href="https://apps.apple.com/nz/app/brewser/id6737429504" target="_blank" rel="noopener noreferrer">
                <img src={imgAndroidStore} alt="Get it on Google Play" className="download-button" />
              </a> */}
            </div>
          </div>
          <div className="image-section">
            <img src={imgCoffee} alt="Coffee logo" className="coffee-image" />
          </div>
        </div>
      </section>

      <section className="story-section">
        <div className="story-content">
          <h3 className="story-title">the <span className="highlight">Brewser</span> story</h3>
          <p className="story-text">
            Welcome to Brewser - the app that revolutionizes how you enjoy your favorite brews and supports local businesses.
            Brewser is all about bringing people together over great coffee, while also earning rewards for your loyalty.
            Join us in discovering new local cafés, tracking your loyalty rewards digitally, and supporting small businesses
            in your community.
          </p>
          <div className="story-buttons">
            <button className="story-button">Join Brewser as a cafe owner</button>
            <button className="story-button">Join Brewser as a user</button>
          </div>
        </div>
      </section>

      {/* Expanded Footer Section */}
      <footer className="footer">
        <p>
          Brewser, powered by <a href="https://more.org.nz" target="_blank">More Limited</a>&nbsp;|&nbsp;
          <a href="#" onClick={() => navigate('/privacypolicy')} rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </footer>
    </div>
  );
};

export default MainScreen;
